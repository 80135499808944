import { render, staticRenderFns } from "./ActivityLogList.vue?vue&type=template&id=63c9cff9&"
import script from "./ActivityLogList.vue?vue&type=script&lang=js&"
export * from "./ActivityLogList.vue?vue&type=script&lang=js&"
import style0 from "./ActivityLogList.vue?vue&type=style&index=0&id=63c9cff9&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtn,QTooltip,QDialog,QChip,QInput,QTh,QPagination,QIcon});
