<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section>
			<div class="text-h6">Filter activity logs</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<FilterByCustomerGroup
				class="col-md-5 col-xs-12"
				:selected_customer_group="selected_customer_group"
				@change-customer-group="updateCustomerGroup"
			/>
			<FilterDate
				class="col-md-5 col-xs-12"
				:selected_date="selected_date"
				@resetDate="resetDate"
				@dateFromSelected="dateFromSelected"
				@dateToSelected="dateToSelected"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Filter" color="primary"	@click="listActivityLogs()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import FilterDate from "@/components/activity_log/FilterDate.vue";
import FilterByCustomerGroup from "@/components/activity_log/FilterByCustomerGroup.vue";
export default {
	name: "FiltersAndSort",
	components: {
		FilterDate,
		FilterByCustomerGroup,
	},
	data (){
		return {
			date_from: "",
			date_to: "",
			date:"",
			cg: "",
		}
	},
	props:{
		selected_date: Object,
		selected_customer_group: Object,
	},
	methods: {
		resetDate(date_range){
			this.date_from = "";
			this.date_to = "";
			this.date = "";
			this.date = date_range
		},
		dateFromSelected(date){
			this.date_from = date;
			this.date = {"id": "custom", "value": "Custom dates"}
			if(this.date_from > this.date_to){
				this.date_to = "";
			}
		},
		dateToSelected(date){
			this.date_to = date;
		},
		updateCustomerGroup(cg){
			this.cg = cg;
		},
		async listActivityLogs(){
			this.$emit('update-filters', {
				"date": this.date,
				"date_from": this.date_from,
				"date_to": this.date_to,
				'cg': this.cg,
			})
			this.$emit('close')
		},
	},
};
</script>
