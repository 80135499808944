<template>
	<div>
		<q-select
			class="q-mb-md"
			label="Search By Dataset"
			v-model="customer_group"
			:options="local_customer_groups"
			:option-label="(item) => item.label"
			@input="updateCustomerGroup(customer_group)"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "FilterByCustomerGroup",
	data (){
		return {
			local_customer_groups: [],
			customer_group: {},
		}
	},
	props: {
		selected_customer_group: Object,
	},
	computed: {
		...mapGetters(["all_customer_groups"]),
	},
	methods: {
		...mapActions(["listCustomerGroups"]),
		async fetchCustomerGroups(){
			await this.listCustomerGroups();
			this.local_customer_groups = [{"id": false, "label": "All Datasets"}];
			this.local_customer_groups = this.local_customer_groups.concat(
				JSON.parse(JSON.stringify(this.all_customer_groups))
			)
		},
		updateCustomerGroup(customer_group){
			this.$emit('change-customer-group', customer_group)
		}
	},
	created() {
		this.fetchCustomerGroups()
		if (!this.customer_group.length){
			this.customer_group = this.selected_customer_group
			this.$emit('change-customer-group', this.customer_group)
		}
	},
};
</script>
