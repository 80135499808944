<template>
	<div>
		<q-select
			class="q-my-md"
			label="View By Date"
			v-model="date_range"
			:options="type_of_date_ranges"
			:option-label="(item) => item.value"
			@input="dateSelected"
		>
		</q-select>
		<div class="flex row justify-around" v-if="date_range.id == 'custom'">
			<q-date
				class="q-mb-md"
				v-model="date_from"
				today-btn
				@input="dateFromSelected"
			/>

			<q-date
				v-if="date_from"
				class="q-mb-md"
				v-model="date_to"
				today-btn
				:options="date_to => date_to >= date_from"
				@input="dateToSelected"
			/>
		</div>
	</div>
</template>
<script>
import { date } from 'quasar'
export default {
	name: "FilterDate",
	props: {
		selected_date: Object,
	},
	data (){
		return {
			date_range: "",
			type_of_date_ranges: [],
			date_from: "",
			date_to: "",
			has_date_to_error: false,
			date_to_error_message: '',
		}
	},
	methods: {
		dateFromSelected(){
			this.$emit("dateFromSelected", new Date(this.date_from))
			if(this.date_from > this.date_to){
				this.date_to = ""
			}
		},
		dateToSelected(){
			if(this.date_from > this.date_to){
				this.has_date_to_error = true
				this.date_to_error_message = 'The end date must be greater than the start date.'
			}
			const date_to = date.addToDate(this.date_to, { hours: 23, minutes: 59, seconds: 59})
			this.$emit('dateToSelected', date_to)
		},
		dateSelected(){
			if (this.date_range.id != 'custom'){
				this.$emit('resetDate', this.date_range)
			}
		}
	},
	created(){
		this.type_of_date_ranges.push({'id':"1_month_ago", "value":'Less than 1 month'})
		this.type_of_date_ranges.push({'id':"between_1_and_3_months", "value":'1 to 3 months ago'})
		this.type_of_date_ranges.push({'id':"between_3_and_6_months", "value":'3 to 6 months ago'})
		this.type_of_date_ranges.push({'id':"between_6_and_12_months", "value":'6 to 12 months ago'})
		this.type_of_date_ranges.push({'id':"more_than_12_months", "value":'More than 12 months'})
		this.type_of_date_ranges.push({"id": "custom", "value": "Custom dates"})

		if (!this.date_range){
			this.date_range = this.selected_date
			this.$emit('resetDate', this.date_range)
		}
	},
};
</script>