<template>
	<div class="field-fab-wrapper">
		<q-btn
			v-if="$q.screen.gt.sm"
			size="md"
			padding="md lg"
			color="primary"
			icon="download"
			label="Export"
			rounded
			@click="generateSummary()"
		/>
		<q-btn
			v-else
			color="primary"
			icon="download"
			size="lg"
			round
			@click="generateSummary()"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Export
			</q-tooltip>
		</q-btn>
	</div>
</template>

<script>
import ActivityLogAPI from '@/services/api/activityLog.js';
import { mapGetters } from "vuex";

export default {
	name: "ExportSummaryActivityLog",
	props: {
		headers: Array,
	},
	data (){
		return {
			file: {
				message: "",
			}
		}
	},
	computed: {
		...mapGetters(["activity_log_filters", "logged_user"])
	},
	methods: {
		async generateSummary() {
			try {
				let data = "";
				data = await ActivityLogAPI.activityLogExportSummary({
					"headers": this.headers,
					"filters": this.activity_log_filters,
				})
				this.file.message = 'Your activity log export was scheduled with ID: ' + data['file_id'] +
					'. You will receive a message with the file on ' + this.logged_user.email + '.';
			} catch(error) {
				this.file.message = 'Export failed.';
			}
			this.$emit('export-message', this.file.message)
		},
	},
};
</script>
<style lang="scss" scoped>
.field-fab-wrapper {
	position: sticky;
	bottom: 20px;
	z-index: 5;
	width: fit-content;
	margin: 10px auto;
}
</style>