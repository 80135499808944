<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Activity logs
		</h1>
		<q-table
			class="text-left"
			row-key="name"
			:columns="columns"
			:data="activity_log_list.length ? activity_log_list : []"
			:loading="pending_results"
			:pagination.sync="pagination"
			:rows-per-page-options="[5, 10, 20, 50]"
			:hide-pagination="false"
			binary-state-sort
			@request="onRequest"
		>
			<template v-slot:top class="row">
				<div class="col-md-7 col-12">
					<q-btn
						@click="show_filters = true"
						flat
						round
						color="dark"
						icon="filter_list"
					>
						<q-tooltip anchor="top middle" :delay="500">
							Filter
						</q-tooltip>
					</q-btn>
					<q-dialog v-model="show_filters">
						<SimpleFilters
							:selected_date="selected_date"
							:selected_customer_group="selected_customer_group"
							@update-filters="updateFilters"
							@close="show_filters=false"
						/>
					</q-dialog>
					<q-chip outline color="primary">{{selected_customer_group_label}}</q-chip>
					<q-chip outline color="primary" v-if="date_label != 'Custom dates'">{{date_label}}</q-chip>
					<q-chip v-else outline color="primary">
						{{date_label}} from {{display_date_from}}
						<span class="q-ml-sm" v-if="display_date_to"> to {{display_date_to}}</span>
					</q-chip>
				</div>
				<q-input
					class="col-md-5 col-12 q-pb-none search-user"
					outline
					v-model="user_string"
					label="Search by user"
					clearable
					clear-icon='close'
					:hint="user_string && user_string.length < 3 ? 'Enter at least 3 characters' : ''"
				/>
			</template>

			<template v-slot:header-cell-created_at="props">
				<q-th :props="props">
					{{ props.col.label }}
					<q-tooltip anchor="top middle" :delay="500">
						{{preferred_timezone_preview}}
					</q-tooltip>
				</q-th>
			</template>
			<template v-slot:pagination>
				<span class="row items-center justify-end">
					<q-pagination
						v-model="pagination.page"
						:boundary-numbers="false"
						direction-links
						:max="max_pages"
						:max-pages="5"
						size="md"
						color="grey-8"
						active-color="primary"
						@click="pageNumber(pagination.page)"
					/>
				</span>
			</template>
			<template v-slot:no-data>
				<div class="row" style="width:100%">
					<div class="flex items-center col-sm-6 col-12">
						<q-icon class="q-mr-sm" size="2em" name="report_problem" />
						{{no_data_label}}
					</div>
					<div class="col-sm-6 col-12 justify-end flex">
						<q-pagination
							v-model="pagination.page"
							direction-links
							:boundary-numbers="false"
							:max="pagination.page"
							:max-pages="5"
							:ellipses="false"
							size="md"
							color="grey-8"
							active-color="primary"
							@click="pageNumber(pagination.page)"
						/>
					</div>
				</div>
			</template>
		</q-table>
		<ExportSummary
			v-if="this.activity_log_list.length"
			:headers="headers"
			@export-message='displayExportMessage'
		/>
	</div>
</template>

<script>

import moment from 'moment-timezone'
import SimpleFilters from "@/components/activity_log/FiltersAndSort.vue";
import ExportSummary from "@/components/activity_log/ExportSummary.vue";
import { mapGetters, mapActions } from "vuex";
import { date } from 'quasar'
export default {
	name: "ActivityLogList",
	components: {
		SimpleFilters,
		ExportSummary,
	},
	data(){
		return {
			headers: ["User", "Dataset", "Description", "Created at"],
			columns: [
				{
					name: 'user_address_string',
					field: row => row.user,
					align: 'left',
					label: 'User',
					sortable: true,
				},
				{
					name: 'customer_group_name',
					field: 'customer_group',
					align: 'left',
					label: 'Dataset',
					sortable: true,
				},
				{
					name: 'description',
					field: 'description',
					align: 'left',
					label: 'Description',
				},
				{
					name: 'created_at',
					field: obj => {
						return this.getDateStart(obj.created_at)
					},
					align: 'left',
					label: 'Created at',
					sortable: true,
				},
			],
			date_from: "",
			date_to: "",
			date: "1_month_ago",
			cg_id: "",
			filters: {},
			order_by: '',
			user_string: "",
			pagination: {
				rowsPerPage: 20,
				rowsNumber: 0,
				sortBy: 'created_at',
				descending: true,
				page: 1,
			},
			preferred_timezone_preview: "",
			preferred_timezone:"",
			date: "",
			filters: {},
			pending_results: false,
			max_pages: 5,
			has_additional_logs: false,
			show_filters: false,
			date_label: '',
			selected_date: {},
			selected_customer_group: {},
			selected_customer_group_label: '',
			display_date_from: '',
			display_date_to: '',
			no_data_label: '',
		}
	},
	computed: {
		...mapGetters([
			"activity_log_list",
			"num_of_al_pages",
			"current_CG",
			"logged_user",
		]),
	},
	methods: {
		...mapActions([
			'getActivityLogs',
			'updateActivityLogFilters',
			'updateInitialCustomerGroup',
			'updateInitialDateAcLogs',
			'clearActivityLogsList'
		]),
		async updateFilters(data){
			this.max_pages = 5
			this.pagination.page = 1
			this.date = data.date.id
			this.selected_date = data.date
			this.date_label = data.date.value
			this.date_from = data.date_from
			this.date_to = data.date_to
			this.cg_id = data.cg.id
			this.selected_customer_group = data.cg
			this.selected_customer_group_label = data.cg.label
			this.display_date_from = data.date_from ? date.formatDate(this.date_from, 'DD/MM/YYYY') : ''
			this.display_date_to = data.date_to ? date.formatDate(this.date_to, 'DD/MM/YYYY') : ''
			this.listActivityLogs();
		},
		async listActivityLogs(page_changed){
			this.no_data_label = ''
			this.pending_results = true
			this.filters = {
				"page_number":this.pagination.page,
				"items_per_page":this.pagination.rowsPerPage,
				"date_from": this.date_from,
				"date_to": this.date_to,
				"date": this.date,
				"is_descending": this.pagination.descending,
				"order_by": this.pagination.sortBy,
				"cg_id": this.cg_id,
				"user_string": this.user_string,
			}

			if (this.date != 'custom' || (this.date == 'custom' && this.date_from)){
				await this.getActivityLogs(this.filters).then(() =>{
					this.pending_results = false
				})
				await this.updateActivityLogFilters(this.filters)
			}
			this.pending_results = false
			this.has_additional_logs = false
			if (!this.activity_log_list.length) {
				this.no_data_label = page_changed ? 'There are no further pages to display.' : 'There are no results matching your search criteria'
			}
		},
		displayExportMessage(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		pageNumber(page_number){
			this.pagination.page = page_number
			let page_changed = true
			this.listActivityLogs(page_changed)
		},
		onRequest(props) {
			const { sortBy, descending, rowsPerPage, page, rowsNumber } = props.pagination
			if (sortBy || descending || rowsPerPage || page){
				this.pagination.rowsPerPage = rowsPerPage
				this.pagination.rowsNumber = rowsNumber
				this.pagination.descending = descending
				this.pagination.sortBy = sortBy
				this.pagination.page = 1
				this.listActivityLogs()
			}
		},
		getDateStart(created_at){
			let date_start = moment.tz(created_at, "YYYY-MMM-DD HH:mm:ss", "UTC");
			date_start = date_start.clone().tz(this.preferred_timezone)
			return date_start.format('DD/MM/YYYY HH:mm');
		},
	},
	created(){
		this.cg_id = this.current_CG.customer_group.id
		this.selected_customer_group = {
			'id': this.current_CG.customer_group.id,
			'label': this.current_CG.customer_group.name
		}
		this.selected_customer_group_label = this.current_CG.customer_group.name
		this.date = "1_month_ago"
		this.date_label = "Less than 1 month"
		this.selected_date = {'id':"1_month_ago", "value":'Less than 1 month'}
	},
	mounted(){
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
			this.preferred_timezone_preview = "Timezone: " + this.preferred_timezone;
		}
		this.listActivityLogs()
	},
	watch:{
		pagination: {
			handler(){
				if (this.pagination.page == this.max_pages && !this.has_additional_logs){
					this.max_pages += 1
				}
			},
			deep: true
		},
		user_string: function(){
			if (this.user_string && (this.user_string.length == '1' || this.user_string.length == '2')){
				return
			}
			this.pagination.page = 1
			this.max_pages = 5;
			this.listActivityLogs()
		}
	},
	destroyed(){
		this.clearActivityLogsList()
	}
};
</script>
<style>
	@media screen and (max-width: 1023px){
		.search-user{
			padding-left: 0.285em;
			margin-left: 4px;
		}
	}
</style>
