<template>
	<ActivityLogList />
</template>

<script>
import ActivityLogList from "@/components/activity_log/ActivityLogList.vue";
export default {
	name: "ActivityLog",
	meta: {
		title: 'Alert Cascade - Activity Logs'
	},
	components: {
		ActivityLogList
	}
}
</script>
<style lang="scss" scoped>
	.router-view-wrapper {
		padding: 1.5rem 3rem;
		background-color: #fff;
		@media (max-width: 768px) {
			padding: 1rem 0.5rem;
		}
	}
</style>
